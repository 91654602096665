<template>
  <v-tab
    v-bind="$attrs"
    :style="cssProps"
    class="deck-tabs-tab"
  >
    <div
      class="deck-tabs-tab__content-wrapper"
    >
      <deck-icon
        v-if="icon"
        :name="icon"
        v-bind="{
          color: 'var(--deck-tabs-color)',
          size: deckTabs.size === 'small' ? 'small' : 'medium',
          ...iconAttrs
        }"
      />

      <span
        v-else-if="counter"
        class="deck-tabs-tab__counter"
      >{{ counter }}</span>

      <!-- @slot Default content slot. Beware of overriding, as it has specific styles to adhere -->
      <slot>
        <span
          class="deck-tabs-tab__text"
          :data-text="text"
        >
          {{ text }}
        </span>
      </slot>

      <!-- @slot For additional content after the text -->
      <slot name="append-tab" />
    </div>
  </v-tab>
</template>

<script>

export default {
  name: 'DeckTabsTab',
  components: {
    DeckIcon: defineAsyncComponent(() => import('~/deck/icon')),
  },
  inject: ['deckTabs'],
  props: {
    icon: {
      type: String,
      default: '',
    },

    iconAttrs: {
      type: Object,
      default: () => ({}),
    },

    text: {
      type: String,
      default: '',
    },

    counter: {
      type: [String, Number],
      default: undefined,
    },
  },
  computed: {
    cssProps() {
      return {
        '--deck-tabs-font-size': this.deckTabs.size === 'small' ? '12px' : '16px',
        '--deck-tabs-gap': this.deckTabs.size === 'small' ? '4px' : '8px',
        '--deck-tabs-padding-inline': this.deckTabs.size === 'small' ? '12px' : '18px',
        '--deck-tabs-flex': this.flexBehavior,
      };
    },

    flexBehavior() {
      // return js new Map based on kind value
      return new Map([
        ['grow', '1 0 auto'],
        ['fit', 'unset'],
        ['equal', '1'],
      ]).get(this.deckTabs.kind);
    },
  },
  created() {
    this.deckTabs.register(this);
  },
  unmounted() {
    this.deckTabs.unregister(this);
  },
};
</script>
<style lang="scss">
.deck-tabs-tab {
  --deck-tabs-color: var(--z-color-label);

  --deck-tabs-font-weight: 400;
  --deck-tabs-font-weight-active: 700;

  --deck-tabs-letter-spacing: 0.04em;
  --deck-tabs-letter-spacing-active: 0.0315em;

  flex: var(--deck-tabs-flex) !important;
  min-width: unset;
  max-width: unset;
  padding-inline: 0;
  text-transform: unset !important;

  &:is(:hover, :focus-within, .v-tab--selected) {
    --deck-tabs-color: var(--z-color-shade);
    --deck-tabs-font-weight: var(--deck-tabs-font-weight-active);
    --deck-tabs-letter-spacing: var(--deck-tabs-letter-spacing-active);
  }

  .v-tabs-slider {
    opacity: 0.75;
  }
}

.deck-tabs-tab__content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--deck-tabs-gap);
  width: 100%;
  height: 100%;
  padding-inline: var(--deck-tabs-padding-inline);
  text-decoration: none;
  font-size: var(--deck-tabs-font-size);
  letter-spacing: 0.04em;
  font-weight: 700;
  color: transparent !important; // Hide layout-setting text in favor of absolute pseudo-element, avoiding layout shift due to font-weight change;
}

.deck-tabs-tab__counter {
  align-items: center;
  background-color: var(--deck-tabs-color);
  border-radius: 50%;
  color: white;
  display: flex;
  font-size: var(--z-font-size-xsmall);
  height: 16px;
  justify-content: center;
  text-align: center;
  width: 16px;
}

.deck-tabs-tab__text {
  position: relative;

  // Visible pseudo element that does not shift layout
  // This also avoid triggering a slide event from vuetify's v-tabs resize observer when hovering
  &::after {
    content: attr(data-text);
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    color: var(--deck-tabs-color);
    font: inherit;
    letter-spacing: var(--deck-tabs-letter-spacing);
    font-weight: var(--deck-tabs-font-weight);
    transition: color 0.3s ease;
  }
}
</style>
